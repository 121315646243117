import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决vue路由跳转连续点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const index = () => import(/* webpackChunkName: "index" */ '../views/index.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
const CustomTour = () => import(/* webpackChunkName: "customTour" */ '../views/CustomTour.vue')
const AppDownload = () => import(/* webpackChunkName: "appDownload" */ '../views/AppDownload.vue')
const HotRoute = () => import(/* webpackChunkName: "hotRoute" */ '../views/HotRoute.vue')
const GoWith = () => import(/* webpackChunkName: "goWith" */ '../views/GoWith.vue')
const TravelCommunity = () => import(/* webpackChunkName: "travelCommunity" */ '../views/TravelCommunity.vue')
// const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
const LatestNews = () => import(/* webpackChunkName: "latestNews" */ '../views/LatestNews.vue')
// const TravelArtcle = () => import(/* webpackChunkName: "travelArtcle" */ '../views/TravelArtcle.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: index,
    redirect: '/home',
    children: [
      {
        // 首页
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        // 定制游
        path: '/customTour',
        name: 'customTour',
        component: CustomTour,
      },
      {
        // 驴友社区
        path: '/travelCommunity',
        name: 'travelCommunity',
        component: TravelCommunity,
      },
      {
        // 结伴
        path: '/goWith',
        name: 'goWith',
        component: GoWith,
      },
      {
        // 热门路线
        path: '/hotRoute',
        name: 'hotRoute',
        component: HotRoute,
      },
      {
        // APP下载
        path: '/appDownload',
        name: 'appDownload',
        component: AppDownload,
      },
      // {
      //   // 登陆/注册
      //   path: '/login',
      //   name: 'login',
      //   component: Login,
      // },
      {
        // 最新资讯
        path: '/latestNews',
        name: 'latestNews',
        component: LatestNews,
      },
      // {
      //   // 旅行文章
      //   path: '/travelArtcle',
      //   name: 'travelArtcle',
      //   component: TravelArtcle,
      // },
    ]
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
