import Vue from 'vue'
import axios from 'axios'

// 正式
axios.defaults.baseURL = 'https://api.mycuttlefish.com/';

// 预发布
// axios.defaults.baseURL = 'http://pre.cuttlefish.vip/';

// 拦截器
// axios.interceptors.request.use(function (config) {
//   config.headers['Cuttlefish-Version'] = '4.13.0'
//   return config;
// });

Vue.prototype.$http = axios